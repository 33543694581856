/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */
/* csSnippet.js */
$(document).ready(function () {
    'use strict';

    var eventIdPostReplace = 'csSnippet:postReplace';
    var eventIdpreAjaxCall = 'csSnippet:preAjaxCall';
    var eventIdpreReplace = 'csSnippet:preReplace';
    // var eventIdformReset = 'csSnippet:formReset';
    // var resetInProgress;

    function preAjax(target) {
        var event = jQuery.Event(eventIdpreAjaxCall);
        event.targetElement = target;
        $(':root').trigger(event);
    }

    function preReplace(oldElement, newElement) {
        var event = jQuery.Event(eventIdpreReplace);
        event.oldElement = oldElement;
        event.newElement = newElement;
        $(':root').trigger(event);
    }


    function registerAjaxLinks(jqueryRootElement) {
        jqueryRootElement.find('a.csSnippet').each(function () {
            $(this).click(function (event) {
                event.preventDefault();
                var targetDiv = $(this).closest('div.csSnippet') || $(this);
                var targetPos = targetDiv.offset().top;

                var successFunction = function (data, status, req) {
                    if (typeof data === 'string') {
                        var d = $('<data></data>');
                        d.text(data);
                        var r = $('<response></response>');
                        r.append(d);
                        data = r[0];
                    }

                    /* scroll scrollTop */
                    $('body').animate({
                        scrollTop: targetPos
                    }, 'fast');

                    replace(data, status, req, targetDiv);
                    loadingPage = false;
                };
                preAjax(targetDiv);
                $('.mdb-select').material_select('destroy');
                $('.mdb-select').material_select();
                
                // don't change URL for SEO and don't add History
                
                /* if (history && history.pushState) {
                    if ($(this).data('change-url-history') === undefined || $(this).data('change-url-history') === true) {
                        history.pushState(null, $(document).find("title").text(), $(this).attr('href')); 
                    }
                }*/ 
                
                var errorFunction = function (jqXHR, textStatus, errorThrown) {
                    console.log('an error occured: ' + errorThrown);
                };
                jQuery.ajax({
                    url: $(this).attr('data-snippet-url'),
                    type: "GET",
                    success: successFunction,
                    error: errorFunction
                });
            });
        });
    }

    function replace(data, statusText, xhr, targetDiv) {

        var newElementText = $(data).find('data').text();
        var newElement = $(newElementText);
        if (targetDiv.hasClass('load_more')) {
            $(targetDiv).find('.article-list-wrapper a.loadMoreLink').parent().remove();
            $(targetDiv).find('.article-list-wrapper .loadMore.trigger').remove();

            $(newElement).find('.article-list').first().find('.divider').remove();
            $(newElement).find('.article-list').each(function (index) {
                $(targetDiv).find('.article-list-wrapper').append($(this));
            });

            $(targetDiv).find('.article-list-wrapper').append($(newElement).find('.article-list-wrapper a.loadMoreLink').parent());
            $(targetDiv).find('.article-list-wrapper').append($(newElement).find('.article-list-wrapper .loadMore.trigger'));

            targetDiv.csInit();

        } else if (targetDiv.hasClass('load_more_widget')) {

            $('.article-list .element.hidden').removeClass('hidden');

            $(targetDiv).find('.more a.loadMoreLink').remove();

            $(newElement).find('article,.fake-article').each(function (index) {
                $(targetDiv).find('.article-list-wrapper').append($(this));
            });

            $(targetDiv).find('.more').append($(newElement).find('.more a.loadMoreLink'));

            var currentPage = $(newElement).find('.currentPage');
            if (currentPage.length > 0 && typeof checkArticles === "function"){
                checkArticles($(newElement).find('.currentPage').text());
            }

            targetDiv.csInit();

        } else {
            if (!newElement) {
                newElement = $(data);
                preReplace(targetDiv, newElement);
                targetDiv = $(':root');
            } else {
                preReplace(targetDiv, newElement);
            }
            targetDiv.replaceWith(newElement);
            // var event = jQuery.Event(eventIdPostReplace);
            // event.replacedTarget = newElement;
            // $(':root').trigger(event);
            /* duplicate init!!! */
            newElement.csInit();
        }
    }

    function showLoadingIndicator(target) {
        target.find('.csPreloader').show();
    }

    function hideLoadingIndicator(target) {
        target.find('.csPreloader').hide();
    }

    $(':root').on(eventIdpreReplace, function (event) {
        hideLoadingIndicator(event.oldElement);
    });
    $(':root').on(eventIdPostReplace, function (event) {
        var target = event.replacedTarget;
        registerAjaxLinks(target);
    });
    $(':root').on(eventIdpreAjaxCall, function (event) {
        showLoadingIndicator(event.targetElement);
    });

    // function init() {
    //     var event = jQuery.Event(eventIdPostReplace);
    //     event.replacedTarget = $(':root');
    //     $(':root').trigger(event);
    // }
    //
    // init();

    $.fn.csSnippet = function () {
        var event = jQuery.Event(eventIdPostReplace);
        event.replacedTarget = $(this);
        $(':root').trigger(event);

    };

    $.csInit(function (e) {
        e.container.csSnippet();
    });



});
